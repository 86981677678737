<template>
  <div class="logo">
    <a href="/">
      <FocalImage
        src="https://za.sunglasshut.com/images/sgh-logo-live.svg"
        alt="logo"
      />
    </a>
  </div>
</template>

<script>
import "./Logo.scss";
import FocalImage from "../FocalImage";
export default {
  name: "Logo",
  components: { FocalImage },
  props: [],
};
</script>
