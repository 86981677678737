<template>
  <CountryItems v-for="item in list" :key="item" :list="list">
    <template v-slot:list-item>
      <a :href="item.url">{{ item.name }}</a>
    </template>
    <template v-slot:children-items>
      <FilterItems :list="item" />
    </template>
  </CountryItems>
</template>

<script>
import "./CountryList.scss";
import CountryItems from "../CountryItems";
import FilterItems from "../FilterItems";
export default {
  name: "CountryList",
  props: ["list"],
  components: { CountryItems, FilterItems },
};
</script>
