<template>
  <div
    class="focal-image"
    :class="className"
    :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
  >
    <img :src="imgSrc" :alt="alt" />
  </div>
</template>

<script>
// import appData from "../../../appData/index";
import "./FocalImage.scss";
import "../../../theme/index.scss";

export default {
  name: "FocalImage",
  props: ["src", "alt", "className"],
  data() {
    return {
      windowWidth: window.innerWidth,
      imgSrc: this.src,
    };
  },
  // watch: {
  //   windowWidth() {
  //     if (this.windowWidth <= 768) {
  //       this.imgSrc = appData.img.image.src.mobile;
  //       console.log(this.imgSrc, "image");
  //     } else if (this.windowWidth > 1280) {
  //       this.imgSrc = appData.img.image.src.desktop;
  //       console.log(this.imgSrc, "image");
  //     } else {
  //       this.imgSrc = appData.img.image.src.tablet;
  //       console.log(this.imgSrc, "image");
  //     }
  //   },
  // },
  // mounted() {
  //   window.addEventListener("resize", this.onResize);
  // },
  // methods: {
  //   onResize() {
  //     this.windowWidth = window.innerWidth;
  //     console.log(this.windowWidth);
  //   },
  // },
};
</script>
