<template>
  <div class="accordion">
    <button @click="toggleAccordion()" class="accordion-button">
      <slot name="title" />
      <div class="accordion-signs">{{ isOpen ? "-" : "+" }}</div>
    </button>
    <div v-if="isOpen" class="accordion-content">
      <slot name="content" />
    </div>
    <div class="accordion-content-desktop">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import "./Accordion.scss";
export default {
  name: "Accordion",
  data() {
    return {
      isOpen: false,
      windowWidth: window.innerWidth,
    };
  },
  created() {
    window.addEventListener("load", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("load", this.onResize);
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    onResize() {
      this.windowWidth = window.matchMedia("(max-width: 768px)").matches;
    },
  },
};
</script>
