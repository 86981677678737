<template>
  <div class="top-bar">
    <div class="top-bar-wrap">
      <a class="prev" @click="showSlides">❮</a>
      <List>
        <template v-slot:items>
          <li
            v-for="slide in slides"
            :key="slide"
            class="top-bar-slide"
            v-show="slide.active"
            :class="{ active: slide.active }"
          >
            <a :href="slide.url">{{ slide.text }}</a>
          </li>
        </template>
      </List>
      <a class="next" @click="showSlides">❯</a>

    </div>
    <CountryFilter class="mx-2" />
    <!--    <div class="top-bar-site">South Africa</div>-->

  </div>

</template>

<script>
import "./TopBar.scss";
import List from "../../atoms/List";
import CountryFilter from "../../molecules/CountryFilter";
export default {
  name: "TopBar",
  components: { List,CountryFilter },
  data() {
    return {
      slides: [
        {
          text: "shop safely from our stores",
          url: "/store-locator",
          active: true,
        },
        {
          text: "find your nearest store",
          url: "/store-locator",
          active: false,
        },
      ],
      timer: null,
    };
  },
  methods: {
    showSlides() {
      let i;
      for (i = 0; i < this.slides.length; i++) {
        this.slides[i].active = !this.slides[i].active;
      }
    },
    rotateSlides() {
      this.timer = setInterval(this.showSlides, 3000);
    },
  },
  mounted() {
    this.rotateSlides();
  },
};
</script>
