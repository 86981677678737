<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `Sunglass Hut® South Africa Online Store | Sunglasses for Women & Men` }}</template>
    <template v-slot:description="{ content }">{{ content ? `${content}` : `Sunglass Hut® South Africa Online Store | Sunglasses for Women & Men` }}</template>
  </metainfo>
  <TopBar />
  <Header />
  <router-view />
  <Footer />
  <cookie-consent
    href="/privacy-policy-sa"
    buttonLabel="I Accept"
    linkLabel="read more here"
    message="We use cookies to improve your experience on our website and to show you personalised content. By using this Site, you consent and/or accept all such cookies. You can read our full privacy policy"
  />
</template>

<script>
import Header from "../src/components/organisms/Header";
import Footer from "../src/components/organisms/Footer";

import "./theme/normalize.css";
import TopBar from "./components/molecules/TopBar";
import CookieConsent from "vue-cookieconsent-component";
import { useMeta } from "vue-meta/dist/vue-meta.esm-browser";

export default {
  components: {
    TopBar,
    Footer,
    Header,
    CookieConsent,
  },
  setup() {
    useMeta({
      title: "Sunglass Hut® South Africa Online Store | Sunglasses for Women & Men",
      htmlAttrs: { lang: "en" },
      description: "Sunglass Hut® South Africa Online Store | Sunglasses for Women & Men",
    });
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      setTimeout(() => {
        var hours = 42;
        var now = new Date().getTime();
        var setupTime = localStorage.getItem("setTime");
        var stor = JSON.parse(localStorage.getItem("items") || "[]");
        var timeDifference = now - setupTime;
        var hoursDifference = Math.floor(timeDifference / (60 * 60 * 1000));

        //console.log(timeDifference,'days diff',hoursDifference);

        //NEW
        var currentDate = new Date()
          .toISOString()
          .substr(0, 10)
          .replace(/-/g, "-");
        // var setupTime = new Date().getTime();
        if (this.$route.query.utm_source != undefined) {
          // console.log(this.$route.query.utm_source,'here');

          let utmitem = {
            utm_source: this.$route.query.utm_source,
            utm_medium: this.$route.query.utm_medium,
            utm_campaign: this.$route.query.utm_campaign,
            utm_content: this.$route.query.utm_content,
            utm_expire: currentDate,
          };

          localStorage.setItem("items", JSON.stringify(utmitem));
          localStorage.setItem("setTime", now);
        } else {
          // console.log('empty');
        }

        //console.log(setupTime,'setup time');
        if (setupTime == null) {
          localStorage.setItem(stor, now);
        } else {
          if (hoursDifference > hours) {
            // console.log('clear');

            localStorage.clear();
            //localStorage.setItem('setupTime', now);
          }
        }
      }, 500);
    },
  },
};
</script>
