export default {
  list: [
    {
      active: false,
      name: "africa",
      id: "africa",
      countries: [
        {
          name: "egypt",
          id: "africa",
          url: "http://mena.sunglasshut.com/en?ctry=EG",
        },
        {
          name: "morocco",
          id: "africa",
          url: "http://mena.sunglasshut.com/en?ctry=MA",
        },
        {
          name: "south africa",
          id: "africa",
          url: "http://za.sunglasshut.com/",
        },
      ],
    },
    {
      active: false,
      name: "america",
      countries: [
        {
          name: "brasil",
          url: "http://www.sunglasshut.com/br",
        },
        {
          name: "canada",
          url: "http://www.sunglasshut.com/ca",
        },
        {
          name: "chile",
          url: "http://cl.sunglasshut.com/cl",
        },
        {
          name: "mexico",
          url: "http://www.sunglasshut.com/mx/",
        },
        {
          name: "usa",
          url: "http://www.sunglasshut.com/us",
        },
      ],
    },
    {
      active: false,
      name: "asia",
      countries: [
        {
          name: "china",
          url: "http://cn.sunglasshut.com/",
        },
        {
          name: "hong kong (china)",
          url: "http://hk.sunglasshut.com/hk",
        },
        {
          name: "singapore",
          url: "http://sg.sunglasshut.com/sg",
        },
        {
          name: "thailand",
          url: "http://th.sunglasshut.com/th",
        },
        {
          name: "india",
          url: "https://in.sunglasshut.com/",
        },
      ],
    },
    {
      active: false,
      name: "europe",
      countries: [
        {
          name: "france",
          url: "http://www.sunglasshut.com/fr",
        },
        {
          name: "germany",
          url: "http://www.sunglasshut.com/de",
        },
        {
          name: "netherlands",
          url: "http://nl.sunglasshut.com/nl",
        },
        {
          name: "portugal",
          url: "http://pt.sunglasshut.com/pt",
        },
        {
          name: "spain",
          url: "https://www.sunglasshut.com/es",
        },
        {
          name: "united kingdom",
          url: "http://www.sunglasshut.com/uk",
        },
      ],
    },
    {
      active: false,
      name: "middle east",
      countries: [
        {
          name: "turkey",
          url: "http://tr.sunglasshut.com/tr",
        },
        {
          name: "bahrain",
          url: "http://mena.sunglasshut.com/en?ctry=BH",
        },
        {
          name: "jordan",
          url: "http://mena.sunglasshut.com/en?ctry=JO",
        },
        {
          name: "kuwait",
          url: "http://mena.sunglasshut.com/en?ctry=KW",
        },
        {
          name: "lebanon",
          url: "http://mena.sunglasshut.com/en?ctry=LB",
        },
        {
          name: "oman",
          url: "http://mena.sunglasshut.com/en?ctry=OM",
        },
        {
          name: "qatar",
          url: "http://mena.sunglasshut.com/en?ctry=QA",
        },
        {
          name: "saudia arabia",
          url: "http://mena.sunglasshut.com/en?ctry=SA",
        },
        {
          name: "united arab emirates",
          url: "http://mena.sunglasshut.com/en?ctry=AE",
        },
      ],
    },
    {
      active: false,
      name: "oceania",
      countries: [
        {
          name: "australia",
          url: "http://www.sunglasshut.com/au",
        },
        {
          name: "new zealand",
          url: "http://www.sunglasshut.co.nz/",
        },
      ],
    },
  ],
};
