<template>
  <div class="footer">
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from "../../molecules/FooterComponent";
export default {
  name: "Footer",
  components: { FooterComponent },
  props: [],
};
</script>
