export default {
  content: [
    {
      heading: "perfect fit",
      description: "Free personalized adjustments, anytime",
      inactiveIcon:
        "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/icon1.png",
      activeIcon:
        "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/icon1a.png",
      activeBackground:
        "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/background.jpg",
      text:
        "We want your new shades to fit and feel as amazing as they" +
        " look. We’ll help keep your sunglasses in great condition. Bring them in for a complimentary adjustment at any Sunglass Hut store. Just walk up to the counter and ask.",
    },
    {
      heading: "custom cleaning",
      description: "At our store, anytime - our treat",
      inactiveIcon:
        "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/icon2.png",
      activeIcon:
        "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/icon2a.png",
      activeBackground:
        "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/background.jpg",
      text:
        "We want your shades to look as good as they did the day you" +
        " bought them. Just bring them in for a complimentary cleaning; anytime, our treat. We are happy to help you look your best.",
    },
    {
      heading: "EASY RETURNS",
      description: "Just bring your receipt within 30 days, no questions asked",
      inactiveIcon:
          "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/icon3.png",
      activeIcon:
          "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/icon3a.png",
      activeBackground:
          "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/background.jpg",
      text:
          "Sometimes things just don't work out. And we totally understand. If you're not thrilled with your purchase, you've got 30 days from the original purchase date to return your purchased sunglasses in original condition to Sunglass Hut for either a full refund or an exchange." +
          " <br> <br>" +
          "You'll just need your proof of purchase and original packaging."
          + "  <br> <br>" +
          "Returns and exchanges only apply to items that Sunglass Hut sells. Gift cards are non-refundable, and cannot be replaced in the event of loss, theft or damage. Our easy returns promise does not apply if you have broken or damaged the sunglasses in any way."
          + "  <br> <br>" +
          "It cannot be used or combined with any other offer, discount or promotion."
      ,
    },

    {
      heading: "OOPS COVERAGE",
      description: "50% off a new pair if you damage your shades within 1 year",
      inactiveIcon:
          "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/icon4.png",
      activeIcon:
          "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/icon4a.png",
      activeBackground:
          "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/background.jpg",
      text:
          "Occasionally sunglasses break. It's sad but true. So if you break or damage your purchased sunglasses within 12 months of the date of purchase, our replacement Coverage is available. This replacement discount is 50% of the full retail price paid at the time of purchase for the damaged sunglasses towards the purchase of a new replacement pair.That’s right: a new pair at 50% off!" +
          " <br> <br>" +
          "Your damaged sunglasses must be returned with proof of purchase to any Sunglass Hut retail store in the country of purchase within 12 months from the original date of purchase. Replacement pairs must be of equal or greater value than the original purchase pair."
          + "  <br> <br>" +
          "Discount may not be combined with other discounts or promotions. Does not apply to loss or theft. May be used once within the 12-month period following the original purchase date. Proof of purchase must be presented. Certain exclusions may apply; see associate for additional details."

    },
    {
      heading: "ASSURED QUALITY",
      description: "Trade in pairs that don't meet the highest standards for up to two years",
      inactiveIcon:
          "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/icon5.png",
      activeIcon:
          "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/icon5a.png",
      activeBackground:
          "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/background.jpg",
      text:
          "Sunglasses are protected by a 2 year manufacturer's warranty. Sunglass Hut will honor manufacturer warranties that cover defects in material or workmanship on the frames, on condition that the original proof of purchase is provided. The warranty does not apply to lose or missing screws, scratched lenses, loose, damaged or missing nose pieces and nose pads. Alteration of products will void warranty. If it is not clear whether the fault is the result of a defect or misuse, it may be necessary for the item to be returned to the manufacturer to determine whether or not you are entitled to a warranty claim." +
          " <br>  <br>" +
          "Should you require a warranty assessment please call 0861 000 850 or email warranty@za.sunglasshut.com and we will collect from your home or business address within South Africa."
     },
  ],
};
