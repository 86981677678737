<template>
  <div class="footer-component-links-inner">
    <div class="footer-component-links">
      <Accordion
        :desktop="desktop"
        :mobile="mobile"
        class="footer-component-links-primary-pages"
      >
        <template v-slot:title><h4>Quick Links</h4></template>
        <template v-slot:content>
          <li v-for="link in quickLinks" :key="link">
            <a :href="link.url" :target="link.target">{{ link.title }}</a>
          </li>
        </template>
      </Accordion>
      <Accordion
        :desktop="desktop"
        :mobile="mobile"
        class="footer-component-links-secondary-pages"
      >
        <template v-slot:title><h4>Brands</h4></template>
        <template v-slot:content
          ><li v-for="link in brandLinks" :key="link">
            <a :href="link.url">{{ link.title }}</a>
          </li>
        </template>
      </Accordion>
      <Accordion
        :desktop="desktop"
        :mobile="mobile"
        class="footer-component-links-secondary-pages"
      >
        <template v-slot:title><h4>About Us</h4></template>
        <template v-slot:content
          ><li v-for="link in aboutLink" :key="link">
          <a :href="link.url">{{ link.title }}</a>
          </li>
        </template>
      </Accordion>
      <Accordion
        :desktop="desktop"
        :mobile="mobile"
        class="footer-component-links-secondary-pages"
      >
        <template v-slot:title><h4>Follow Us</h4></template>
        <template v-slot:content
          ><li v-for="link in socialLinks" :key="link">
            <a :href="link.url">{{ link.title }}</a>
          </li>
        </template>
      </Accordion>
      <Accordion
        :desktop="desktop"
        :mobile="mobile"
        class="footer-component-links-secondary-pages"
      >
        <template v-slot:title><h4>Need Help?</h4></template>
        <template v-slot:content
          ><li v-for="link in infoLinks" :key="link">
            <a id="contact-details" v-if="link.url" :href="link.url">{{
              link.title
            }}</a>
            <span v-else>{{ link.title }}</span>
          </li>
        </template>
      </Accordion>
    </div>
  </div>
  <div class="footer-component-info">
    <div class="footer-component-info-wrap">
      <p>
        © {{ getYear }} Sunglass Hut All Rights Reserved. <span>|</span> <br />
        Pictures and images on the site are for illustration purposes only
      </p>
    </div>
  </div>
</template>

<script>
import Footer from "../../../appData/index";
import Accordion from "../Accordion";
import "./FooterComponent.scss";

export default {
  name: "FooterComponent",
  components: { Accordion },
  data() {
    return {
      quickLinks: Footer.footer.quick_links,
      brandLinks: Footer.footer.brands,
      aboutLink: Footer.footer.about_us,
      socialLinks: Footer.footer.follow_us,
      infoLinks: Footer.footer.info,
      getYear: new Date().getFullYear(),
      desktop: true,
      mobile: false,
    };
  },
};
</script>
