<template>
  <div class="product-card">
    <Card v-for="item in items" :key="item" :item="item" />
  </div>
</template>

<script>
import "./ProductCard.scss";

import Card from "../../molecules/Card/index";
export default {
  name: "ProductCard",
  components: { Card },
  props: ["items"],
  data() {
    return {

    };
  },
};
</script>
