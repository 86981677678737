<template>
  <ul>
    <slot name="items"></slot>
  </ul>
</template>

<script>
import "./List.scss";
export default {
  name: "List",
  props: [],
};
</script>
