<template>
  <div class="product-item text-center" v-for="(item, i) in Items" :key="i" @mouseover="showByIndex = i"
    @mouseout="showByIndex = null">
    <a :href="item.url">
      <div class="product-img">
        <img v-if="showByIndex === i" v-show="showByIndex === i"
          :src="`https://za.sunglasshut.com/shared_assets/product_images/${item.imageOne}_030A.jpg`"
          :alt="`${item.name}`" />
        <img v-else :src="`https://za.sunglasshut.com/shared_assets/product_images/${item.imageOne}_000A.jpg`"
          :alt="`${item.name}`" />
      </div>
      <div class="product-info pb-0">
        <div class="product-name">{{ item.brand }}</div>
        <p class="product-price red-text mb-0">{{ item.sale_price }}</p>
        <p class="product-price">{{ item.price }}</p>
        <a :href="`${item.url}`" class="btn-shop button">Shop Now</a>
      </div>
    </a>
  </div>
</template>
<script>
import "./HomeProducts2.scss";
export default {
  name: "HomeProducts2",
  data() {
    return {
      showByIndex: null,
      hover: false,
      active: true,
      timer: null,
      current: 0,
      Items: [
        {
          imageOne: "8056262145548",
          brand: "Ray-Ban",
          price: "R2770.00",
          url: "/sunglasshut-sa/Ray-Ban/RB4425-Teru-8056262145548",
        },
        {
          imageOne: "888392636935",
          brand: "Oakley",
          price: "R2630.00",
          url: "/sunglasshut-sa/Oakley/OO9280-BXTR-888392636935",
        },
        {
          imageOne: "8056262029725",
          brand: "Jimmy Choo",
          price: "R4630.00",
          url: "/sunglasshut-sa/Jimmy%20Choo/JC5008-8056262029725",
        },
        {
          imageOne: "8056262014035",
          brand: "Versace",
          price: "R4550.00",
          url: "/sunglasshut-sa/Versace/VE4466U-8056262014035",
        },
        {
          imageOne: "888392601803",
          brand: "Oakley",
          price: "R2630.00",
          url: "/sunglasshut-sa/Oakley/OO9280-BXTR-888392601803",
        },
        {
          imageOne: "8056262085400",
          brand: "Burberry",
          price: "R4890.00",
          url: "/sunglasshut-sa/Burberry/BE4425U-8056262085400",
        }
      ],
    };
  },
};
</script>
