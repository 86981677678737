import img from "./dummyData";
import footer from "./footerLinks";
import navigation from "./navigation";
import countries from "./countryFilter";
import privacyPolicy from "./privacy-policy";
import ourStory from "./ourStory";
import routes from "./routes";
import perfectPair from "./perfectPair";
import brandInfo from "./brandInfo";
import careers from "./careers";
import homeslider from "./homeslider";

const data = {
  img,
  footer,
  navigation,
  countries,
  privacyPolicy,
  ourStory,
  routes,
  perfectPair,
  brandInfo,
  careers,
  homeslider
};

export default data;
