export default {
  quick_links: [
    {
      title: "privacy policy",
      url: "/privacy-policy-sa",
      target: ""
    },
    {
      title: "PAIA Manual",
      url: "/privacy-policy-sa?target=section-paia",
      target: ""
    },
    {
      title: "find a store",
      url: "/store-locator",
      target: ""
    },
  ],
  brands: [
    {
      title: "ray-ban",
      url: "/products/Ray-Ban/",
    },
    {
      title: "oakley",
      url: "/products/Oakley/",
    },
    {
      title: "versace",
      url: "/products/Versace/",
    },
    {
      title: "prada",
      url: "/products/Prada/",
    },
    {
      title: "burberry",
      url: "/products/Burberry/",
    },

  ],
  about_us: [
    {
      title: "our story",
      url: "/our-story-sunglasshutsa",
    },
    {
      title: "careers",
      url: "/career-page-sunglass-hut",
    },
  ],
  follow_us: [
    {
      title: "instagram",
      url: "https://www.instagram.com/sunglasshut/",
    },
    {
      title: "facebook",
      url: "https://www.facebook.com/sunglasshutglobal/",
    },
    {
      title: "tik tok",
      url: "https://www.tiktok.com/@sunglasshut?",
    },
    {
      title: "linkedin",
      url: "https://www.linkedin.com/company/sunglasshut",
    },
  ],
  info: [
    {
      title: "Call: +27 86 100 0850",
      url: "tel:+27 86 100 0850",
    },
    {
      title: "monday - friday 9AM - 5PM",
      url: "",
    },
    {
      title: "info@sunglasshut.co.za",
      url: "mailto:info@sunglasshut.co.za",
    },
  ],
};
