<template>
  <div class="menu-button" @click="toggleNav" :class="{ opened: isOpen }">
    <div class="menu-button-box">
      <div class="line" />
    </div>
  </div>
</template>

<script>
import "./MenuButton.scss";
export default {
  name: "MenuButton",
  props: ["toggleNav", "isOpen"],
};
</script>
