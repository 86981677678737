<template>
  <div class="list-items">
    <div v-if="list.brandsListx">
      <!-- <h4>&nbsp;</h4> -->
      <List>
        <template v-slot:items>
          <li v-for="brand in list.brandsListx" :key="brand">
            <a :href="brand.url">{{ brand.name }}</a>
            <span v-if="brand.new" class="new-brand-label">New</span>
          </li>
        </template>
      </List>
    </div>
    <div v-if="list.brandsListy">
      <!-- <h4>&nbsp;</h4> -->
      <List>
        <template v-slot:items>
          <li v-for="brand in list.brandsListy" :key="brand">
            <a :href="brand.url">{{ brand.name }}</a>
            <span v-if="brand.new" class="new-brand-label">New</span>
          </li>
        </template>
      </List>
    </div>
    <div v-if="list.shape">
      <h4>{{ list.shape }}</h4>
      <List>
        <template v-slot:items>
          <li v-for="shape in list.shapeList" :key="shape">
            <a :href="shape.url" :class="{ bold: shape.bold }">{{
              shape.name
            }}</a>
          </li>
        </template>
      </List>
    </div>
    <div v-if="list.brands">
      <h4>{{ list.brands }}</h4>
      <List>
        <template v-slot:items>
          <li v-for="brand in list.brandList" :key="brand">
            <a :href="brand.url">{{ brand.name }}</a>
          </li>
        </template>
      </List>
    </div>

  </div>
</template>

<script>
import "./ListItems.scss";
import List from "../../atoms/List";
export default {
  name: "ListItems",
  props: ["list", "navigation"],
  components: { List },
};
</script>
