export default {
  content: [
    {
      img: "https://cdn.anscommerce.com/image/data/banners_about_ourstory.png",
      text: "",
    },
    {
      img: "https://cdn.anscommerce.com/image/data/image-1.jpg",
      text:
        "<p>At Sunglass Hut, our mission is to be the premier shopping" +
        " and inspiration destination for the top brands, latest trends and" +
        " exclusive styles of high quality fashion and performance" +
        " sunglasses.</p>",
    },
    {
      img: "https://cdn.anscommerce.com/image/catalog/brandstore/sunglass/static-page/SGH_ourstory_01.jpg?MOD=AJPERES&lmod=-631612464",
      text: "",
    },
    {
      img: "https://cdn.anscommerce.com/image/catalog/brandstore/sunglass/static-page/SGH_ourstory_02.jpg?MOD=AJPERES&lmod=-631612464",
      text: "",
    },
    {
      img: "https://cdn.anscommerce.com/image/catalog/brandstore/sunglass/static-page/SGH_ourstory_03.jpg?MOD=AJPERES&lmod=-631612464",
      text: "",
    },
    {
      img: "https://cdn.anscommerce.com/image/catalog/brandstore/sunglass/static-page/SGH_ourstory_04.png?MOD=AJPERES&lmod=-631612464",
      text: "",
    },
    {
      img: "https://cdn.anscommerce.com/image/catalog/brandstore/sunglass/static-page/SGH_ourstory_05.jpg?MOD=AJPERES&lmod=-631612464",
      text: "",
    },
    {
      img: "https://cdn.anscommerce.com/image/catalog/brandstore/sunglass/static-page/SGH_ourstory_MAX_v5_083116_banner_EN.JPG?MOD=AJPERES&lmod=-631612464",
      text: "",
    },
    {
      img: "https://cdn.anscommerce.com/image/catalog/brandstore/sunglass/static-page/SGH_ourstory_07.jpg?MOD=AJPERES&lmod=-631612464",
      text: "",
    },
    {
      img: "https://cdn.anscommerce.com/image/catalog/brandstore/sunglass/static-page/SGH_ourstory_08.jpg?MOD=AJPERES&lmod=-631612464",
      text: "",
    },
  ],
};
