<template>
  <MenuItems
    v-for="propVal in list"
    :key="propVal"
    :child="propVal.children"
    class="menu-items"
  >
    <template v-slot:list-item>
      <a :href="propVal.url" :target="propVal.target"> {{ propVal.title }}</a>
    </template>
    <template v-slot:children-items>
      <ListItems :list="propVal" />
    </template>
  </MenuItems>
</template>

<script>
import "./Menu.scss";
import MenuItems from "../MenuItems";
import ListItems from "../ListItems";
export default {
  name: "Menu",
  components: { MenuItems, ListItems },
  props: ["list"],

  data: function() {
    return {
      propVal: this.list,
    };
  },

  mounted() {

      var stor = JSON.parse(localStorage.getItem("items") || "[]");
      var utm_source = '';
      var utm_campaign = 'site';
      if (stor.utm_source !== undefined && stor.utm_source !== null) {
        utm_source = "_" + stor.utm_source;
      }
      if (stor.utm_campaign !== undefined && stor.utm_campaign !== null) {
        utm_campaign = stor.utm_campaign
      }

      this.propVal[5].url = 'https://www.zando.co.za/mlp-sunglass-hut/?utm_source=zando_sgh' + utm_source + '&utm_medium=link&utm_campaign=' + utm_campaign;
    }


};
</script>
