// main.js

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import InstantSearch from 'vue-instantsearch/vue3/es';
import cookieconsent from 'vue-cookieconsent-component';
import './index.css';
import { createMetaManager } from 'vue-meta/dist/vue-meta.esm-browser'

const app = createApp(App).use(createMetaManager());

app.config.globalProperties.database = process.env.VUE_APP_DATABASE;
window.database = process.env.VUE_APP_DATABASE;
app.config.globalProperties.algoliaIndex = 'sunglasshut_products';
app.config.globalProperties.imageBase = 'https://za.sunglasshut.com/shared_assets/';
app.config.globalProperties.imageBaseCms = 'https://za.sunglasshut.com/images/';
app.config.globalProperties.apiId = 'ZIETC15FK5';
app.config.globalProperties.apiKey = 'f368650b8a7822fad1bda1f8be2ada53';

app.use(router, InstantSearch, 'cookie-consent', cookieconsent);
import VueCookies from 'vue-cookies';
app.use(VueCookies.set('cookies_session', 'sessionValue', '24h'));
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCc8aqeGjGKpkNHB_u3H6cULhVG8ymMMKo',
        libraries: 'places',
    },
});

// Add a listener for the 'load' event
window.addEventListener('load', () => {
    // Check if the URL contains the anchor '#promo1'

    setTimeout(() => {
        if (window.location.hash === '#promo1') {
            // Scroll to the anchor element
            const anchorElement = document.getElementById('promo1');
            if (anchorElement) {
                anchorElement.scrollIntoView();
            }
        }
    }, 500);

});

app.mount('#app');
