<template>
  <button class="button">{{ text }}</button>
</template>

<script>
import "./Button.scss";
export default {
  name: "Button",
  props: ["text"],
};
</script>
