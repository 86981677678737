<template>
  <List>
    <template v-slot:items>
      <li
        v-for="country in list.countries"
        :key="country"
        class="country-filter-items"
      >
        <a :href="country.url">{{ country.name }}</a>
      </li>
    </template>
  </List>
</template>

<script>
import "./FilterItems.scss";
import List from "../../atoms/List";
export default {
  name: "FilterItems",
  components: { List },
  props: ["list"],
};
</script>
