<template>
<div class="navigation" :class="{ opened: isOpen }">
  <MenuButton :toggleNav="toggleNav" :isOpen="isOpen" />
  <Modal class="navigation-container" :class="{ openNavigation: isOpen }" @click="toggleNav" />
  <List class="navigation-list" :class="{ openNav: isOpen }">
    <template v-slot:items>
      <Menu :list="list" />
    </template>
  </List>
</div>
</template>

<script>
import "./Navigation.scss";
import MenuButton from "../../atoms/MenuButton";
import Menu from "../Menu";
import Modal from "../../atoms/Modal";
import List from "../../atoms/List";
export default {
  name: "Navigation",
  components: {
    List,
    Modal,
    MenuButton,
    Menu
  },
  inject: ["data"],
  data() {
    return {
      isOpen: false,
      list: this.data.navigation.navigation,
    };
  },
  methods: {
    toggleNav() {
      this.isOpen = !this.isOpen;
      document.body.style.overflow = this.isOpen ? 'hidden' : ''
    },
  },
};
</script>
