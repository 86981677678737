<template>
  <div class="modal" />
</template>

<script>
import "./Modal.scss";
export default {
  name: "Modal",
  props: [],
};
</script>
