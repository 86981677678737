<template>


  <div id="ourStoryLp">

    <div id="playTheCompainVideo" class="playTheCompainVideo">
      <img loading=lazy  class="displayDesktop imgHeroBlock" src="https://cdn.zando.co.za/cms/sunglass-hut/50-years2.jpg" alt="img hero" style="display: block;">
      <img loading=lazy class="displayMobile imgHeroBlockMobile" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/M/SNGH_Header.jpg" alt="img hero">
    </div>

    <p class="generalText_intro">Born in 1971, Sunglass Hut started as a small family business running a kiosk in a Miami mall. After 50 years and over 3,000 stores worldwide, we’re celebrating where we’ve been and where we’re going – bringing that same family feel and welcoming spirit as the very first kiosk to our stores today.</p>

    <div class="wrapDiscoverMoreBtn">
      <a href="#collage1" class="X_OurStory_DiscoverMore_cta discoverMoreBtn" data-description="Discover more button">Discover more</a>
      <img loading=lazy src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/D/Icon_Arrow.png" class="arrowIconImg" alt="arrow icon">
    </div>

    <div id="collage1" class="collageBlock">
      <img loading=lazy class="displayDesktop b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/D/SNGH_Collage_01.jpg" alt="img sunglasses">
      <img loading=lazy class="displayMobile b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/M/SNGH_Collage_01.jpg" alt="img sunglasses">
      <div class="wrapTextCollage">
        <h2 class="generalTitle">1971</h2>
        <p class="generalText padding1">An idea is born. <br> The first-ever kiosk opens in the Miami Dadeland mall.</p>
      </div>
    </div>

    <div id="collage2" class="collageBlock">
      <img loading=lazy class="displayDesktop b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/D/SNGH_Collage_02.jpg" alt="img sunglasses">
      <img loading=lazy class="displayMobile b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/M/SNGH_Collage_02.jpg" alt="img sunglasses">
      <div class="wrapTextCollage">
        <h2 class="generalTitle">1994-1996</h2>
        <p class="generalText padding1">Sunglass Hut enters Australia and the United Kingdom.</p>
      </div>
    </div>

    <div id="collage3" class="collageBlock">
      <img loading=lazy class="displayDesktop b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/D/SNGH_Collage_03.jpg" alt="img sunglasses">
      <img loading=lazy class="displayMobile b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/M/SNGH_Collage_03.jpg" alt="img sunglasses">
      <div class="wrapTextCollage">
        <h2 class="generalTitle">1998</h2>
        <p class="generalText padding1">Sunglass Hut ecommerce launches.</p>
      </div>
    </div>

    <div id="collage4" class="collageBlock">
      <img loading=lazy class="displayDesktop b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/D/SNGH_Collage_04.jpg" alt="img sunglasses">
      <img loading=lazy class="displayMobile b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/M/SNGH_Collage_04.jpg" alt="img sunglasses">
      <div class="wrapTextCollage">
        <h2 class="generalTitle">2000</h2>
        <p class="generalText padding1"><span class="boldText">More than a functional accessory.</span> <br>
          Sunglass Hut joins Luxottica and their family of iconic brands.</p>
      </div>
    </div>

    <div id="collage5" class="collageBlock">
      <img loading=lazy  class="displayDesktop b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/D/SNGH_Collage_05.jpg" alt="img sunglasses">
      <img loading=lazy class="displayMobile b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/M/SNGH_Collage_05.jpg" alt="img sunglasses">
      <div class="wrapTextCollage">
        <h2 class="generalTitle">2007-2015</h2>
        <p class="generalText padding1">Sunglass Hut expands into India, South Africa, Brazil, Mexico, Spain, Portugal, China, Indonesia and Thailand.</p>
      </div>
    </div>

    <div id="collage6" class="collageBlock">
      <img loading=lazy class="displayDesktop b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/D/SNGH_Collage_06.jpg" alt="img sunglasses">
      <img loading=lazy class="displayMobile b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/M/SNGH_Collage_06.jpg" alt="img sunglasses">
      <div class="wrapTextCollage">
        <h2 class="generalTitle">2018</h2>
        <p class="generalText padding1">Sunglass Hut editorial stories begin. <br> Tying our statement-making shades to fashion trends around the world.</p>
      </div>
    </div>

    <div id="collage7" class="collageBlock">
      <img loading=lazy class="displayDesktop b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/D/SNGH_Collage_07.jpg" alt="img sunglasses">
      <img loading=lazy class="displayMobile b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/M/SNGH_Collage_07.jpg" alt="img sunglasses">
      <div class="wrapTextCollage">
        <h2 class="generalTitle">2019</h2>
        <p class="generalText padding1">Sunglass Hut launches its first customizable products and the first in-store digital shopping tools are born to enrich the omnichannel experience worldwide.</p>
      </div>
    </div>

    <div id="collage8" class="collageBlock">
      <img loading=lazy class="displayDesktop b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/D/SNGH_Collage_08.jpg" alt="img sunglasses">
      <img loading=lazy class="displayMobile b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/M/SNGH_Collage_08.jpg" alt="img sunglasses">
      <div class="wrapTextCollage">
        <h2 class="generalTitle">2020</h2>
        <p class="generalText padding1">Sunglass Hut launches #thesunwithin campaign as a way to connect with customers and support their well-being during the pandemic.</p>
      </div>
    </div>

    <div id="collage9" class="collageBlock">
      <img loading=lazy class="displayDesktop b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/D/SNGH_Collage_09.jpg" alt="img sunglasses">
      <img loading=lazy class="displayMobile b-lazy b-loaded" src="https://media.sunglasshut.com/2021/Landing-page/03_OurStory/M/SNGH_Collage_09.jpg" alt="img sunglasses">
      <div class="wrapTextCollage">
        <h2 class="generalTitle">2021</h2>
        <p class="generalText padding1">Sunglass Hut turns 50! <br> After 50 years, the sun still inspires everything we do. Cheers to 50 more years of bringing you warmth and joy through our sunny styles.</p>
      </div>
    </div>




  </div>
</template>

<script>
import "./OurStory.scss";
import data from "../../appData";
import { useMeta } from "vue-meta/dist/vue-meta.esm-browser";

export default {
  name: "OurStory",
  components: {  },
  data() {
    return {
      images: data.ourStory.content,
    };
  },
  created () {
    
  },
  setup() {
    useMeta({
      title:
        "Sunglass Hut® South Africa Online Store | Our Story",
      description:
        "Sunglass Hut® South Africa Online Store | Our Story",
    });
  },

};
</script>
