<template>
  <div class="header" :class="{ fixed: stickyNav }">
    <Logo />
    <Navigation />
    <Search />
  </div>
</template>

<script>
import "./Header.scss";
import data from "../../../appData/";
import Search from "../Search";
import Logo from "../../atoms/Logo";
import Navigation from "../../molecules/Navigation";

export default {
  name: "Header",
  components: { Search, Navigation, Logo },
  provide() {
    return {
      data,
    };
  },
  data() {
    return {
      stickyNav: null,
    };
  },
  methods: {
    checkHeight() {
      if (window.pageYOffset > 90) {
        this.stickyNav = true;
      } else {
        this.stickyNav = false;
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.checkHeight);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.checkHeight);
  },
};
</script>
