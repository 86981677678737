<template>
  <div class="search">
    <AlgoliaAutocomplete />


  </div>
</template>

<script>
import "./Search.scss";

import AlgoliaAutocomplete from "../../molecules/AlgoliaAutocomplete";

export default {
  name: "Search",
  components: { AlgoliaAutocomplete,},
  props: [],
};
</script>
