<template>
  <div class="product-item text-center" v-for="(item, i) in Items" :key="i" @mouseover="showByIndex = i"
    @mouseout="showByIndex = null">
    <a :href="item.url">
      <div class="product-img">
        <img v-if="showByIndex === i" v-show="showByIndex === i"
          :src="`https://za.sunglasshut.com/shared_assets/product_images/${item.imageOne}_030A.jpg`"
          :alt="`${item.name}`" />
        <img v-else :src="`https://za.sunglasshut.com/shared_assets/product_images/${item.imageOne}_000A.jpg`"
          :alt="`${item.name}`" />
      </div>
      <div class="product-info pb-0">
        <div class="product-name">{{ item.brand }}</div>
        <div v-if="item.band_sub" class="product-name-sub">{{ item.band_sub }}</div>
        <div v-if="item.band_sub2" class="product-name-sub2">{{ item.band_sub2 }}</div>
        <div v-if="item.text" class="product-name-sub2 mt-2">{{ item.text }}</div>
        <p v-if="item.sale_price" class="product-price red-text mb-0">{{ item.sale_price }}</p>
        <p v-if="item.price" :class="[item.sale_price ? 'product-price line-through' : 'product-price']">{{ item.price }}
        </p>
       <!-- <a :href="`${item.url}`" class="btn-shop button">Shop Now</a>-->
      </div>
    </a>
  </div>
</template>
<script>
import "./HomeProducts.scss";
export default {
  name: "HomeProducts",
  data() {
    return {
      showByIndex: null,
      hover: false,
      active: true,
      timer: null,
      current: 0,
      Items: [
        {
          imageOne: "805289126591",
          brand: "Ray-Ban",
          price: "R3500.00",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB2140-WAYFARER-805289126591",
          text: ""
        },
        {
          imageOne: "805289114567",
          brand: "Ray-Ban",
          price: "R3500.00",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB3025-AVIATOR-LARGE-METAL-805289114567",
          text: ""
        },
        {
          imageOne: "8056597328135",
          brand: "Ray-Ban",
          price: "R3500.00",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB3016-CLUBMASTER-8056597328135",
          text: ""
        },
        {
          imageOne: "8056262099353",
          brand: "Ray-Ban",
          price: "R3500.00",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB2140-Original-Wayfarer-Change-8056262099353",
          text: ""
        },
        {
          imageOne: "8056597142441",
          brand: "Ray-Ban",
          price: "R7R3500320.00",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB3447-ROUND-METAL-8056597142441",
          text: ""
        },
        {
          imageOne: "805289467052",
          brand: "Ray-Ban",
          price: "R3700.00",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB3025-AVIATOR-LARGE-METAL-805289467052",
          text: ""
        }
      ],
    };
  },
};
</script>
