import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home/Home.vue";
import ProductListing from "../views/ProductListing";
import Search from "../views/Search";
// import Routes from "../views/Routes/index.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // {
  //   path: "/:id",
  //   name: "Dynamic routes",
  //   component: Routes,
  // },
  {
    path: "/privacy-policy-sa",
    name: "Privacy Policy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PrivacyPolicy/index"),
  },
  {
    path: "/sunglasshut-sa-women/:id",
    name: "Product Listing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductListing/index"),
  },
  {
    path: "/sunglasshut-sa-brand/:brand",
    name: "Product Listing Brand",
    component: ProductListing,
    props: { brands: true, genders: false },
  },
  {
    path: "/products",
    name: "Search All",
    component: Search,
    props:true,
  },
  {
    path: "/products/:brand*",
    name: "Search Brand",
    component: Search,
    props:true,
  },

  {
    path: "/perfect-pair-promise",
    name: "Perfect Pair",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PerfectPair/index"),
  },
  {
    path: "/store-locator",
    name: "Store Locator",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/StoreLocator/index"),
  },
  {
    path: "/career-page-sunglass-hut",
    name: "Careers",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Careers/index"),
  },
  {
    path: "/our-story-sunglasshutsa",
    name: "Our Story",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/OurStory/index"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/store-locator",
    name: "Store Locator",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/StoreLocator/index"),
  },
  {
    path: "/store-locator2",
    name: "Store Locator2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/StoreLocator2/index"),
  },
  {
    path: "/sunglasshut-sa/:brand/:product",
    name: "Product Details",
    props:true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/ProductDetails/index"),
  },

  {
    path: "/find-every-shade",
    name: "everyshade",
    props:true,
    component: () =>
        import( "../views/every-shade/index"),
  },
  {
    path: "/gift-guide",
    name: "giftguide",
    props:true,
    component: () =>
        import( "../views/gift-guide/index"),
  },

  {
    // path: "*",
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//patch to be removed
router.beforeResolve((to, from, next) => {
  const url = window.location.href;
  if (url === 'https://za.sunglasshut.com/#promo1?utm_source=hypebeast&utm_medium=referral&utm_content=promo&utm_campaign=find_every_shade') {
    const newUrl = 'https://za.sunglasshut.com/?utm_source=hypebeast&utm_medium=referral&utm_content=promo&utm_campaign=find_every_shade#promo1';
    window.history.replaceState(null, '', newUrl);

    // Add a slight delay (100ms) before refreshing the page
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
  else if (url === 'https://za.sunglasshut.com/#promo1?utm_source=Kickoff&utm_medium=referral&utm_content=promo&utm_campaign=OakleyxKM') {
    const newUrl = 'https://za.sunglasshut.com/?utm_source=Kickoff&utm_medium=referral&utm_content=promo&utm_campaign=OakleyxKM#promo1';
    window.history.replaceState(null, '', newUrl);

    // Add a slight delay (100ms) before refreshing the page
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
  else if (url === 'https://za.sunglasshut.com/#promo1?utm_source=spotify&utm_medium=referral&utm_content=promo&utm_campaign=find_every_shade') {
    const newUrl = 'https://za.sunglasshut.com/?utm_source=spotify&utm_medium=referral&utm_content=promo&utm_campaign=find_every_shade#promo1';
    window.history.replaceState(null, '', newUrl);

    // Add a slight delay (100ms) before refreshing the page
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
  else if (url === 'https://za.sunglasshut.com/#promo1?utm_source=instagram&utm_medium=referral&utm_content=promo&utm_campaign=find_every_shade') {
    const newUrl = 'https://za.sunglasshut.com/?utm_source=instagram&utm_medium=referral&utm_content=promo&utm_campaign=find_every_shade#promo1';
    window.history.replaceState(null, '', newUrl);

    // Add a slight delay (100ms) before refreshing the page
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  else


  {
    next(); // Continue with the regular navigation
  }
});
export default router;
