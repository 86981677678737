<template>
  <li class="country-filter-items" @click="activeItem">
    <div v-if="hide" class="country-filter-items-wrap">
      <slot name="list-item" />
      <i class="fa fa-angle-right" />
    </div>
    <div v-if="active">
      <slot name="children-items" />
    </div>
  </li>
</template>

<script>
import "./CountryItems.scss";
export default {
  name: "CountryItems",
  props: ["list"],
  data() {
    return {
      active: false,
      hide: true,
    };
  },
  methods: {
    activeItem() {
      this.active = !this.active;
      this.hide = !this.hide;
    },
    // filterId() {
    //   const x = this.list.filter(
    //     (item) => item.id === item.countries.find((v) => v.id)
    //   );
    //   console.log(x, "x");
    // },
  },
};
</script>
