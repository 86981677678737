<template>
  <div class="page-heading">
    <div v-html="pageHeading"></div>
    <div v-html="pageDescription"></div>
  </div>

  <ais-instant-search :search-client="searchClient" :index-name="database" :routing="routing">

    <ais-configure :hits-per-page.camel="12"/>
    <ais-search-box v-show="false" />
    <div class="flex justify-between sm:flex-wrap">
      <div class="sm:w-full md:w-1/6 p-3">
        <ais-breadcrumb v-show="false" root-path="../"
                        :attributes="['brand']"
                        :transform-items="transformItems"
        />
        <div class="detailed-info-bread">
          <a href="/">Home</a> / <a v-if="brandx" :href="`../../products/${brandx}/`">{{brandx}}</a>
        </div>

      </div>
        
      <div class="order-last md:w-96 p-3 sm:w-full ">
        <div class="inline-block md:m-1 lg:float-none sm:m-1 sm:float-right">
          <ais-sort-by
              :items="[
    { value: this.database, label: 'Sort By' },
    { value: this.database+'_price_asc', label: 'Price asc.' },
    { value: this.database+'_price_desc', label: 'Price desc.' },
  ]"
          />
        </div>
        <div class="detailed-info-specifics inline-block md:m-0  sm:m-1"  @click="openSpecs">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor">
            <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
          </svg>  Filter by</div>

      </div>

    </div>
    <div class="grid grid-cols-1 gap-4">
      <div class="col-start-1 col-end-6">
        <div class=" m-3">

          <ais-current-refinements />
        </div>
      </div>
    </div>


    <div class="grid grid-cols-1 gap-4">
      <div class="col-start-1 col-end-6">
        <div class="brand-info-landing" v-if="brands">
          <div v-for="items in brands" :key="items.name">
            <div v-if="items.name === this.brandx">
              <p class="justify-center"><img :src="`${imageBase}${items.img}`" :alt="items.name"></p>
              <div class="page-heading">
                <h1>Explore {{ items.name }} Sunglasses Collection</h1>
                <p>Immerse yourself in the essence of {{ items.name }}'s signature style. Explore a curated selection of premium eyewear, where each pair reflects the brand's commitment to quality and innovation. Elevate your look with fashion-forward designs and timeless classics. Discover the art of eyewear excellence – shop {{ items.name }} now. </p>
              </div>
              <!-- <p>{{ items.desc }}</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row sm:flex-wrap-reverse lg:flex-nowrap">
      <div class="basis-1/4 ">


      </div>
      <div class="basis-1">
        <div class="product-listing-content">
          <ais-hits>
            <template v-slot:item="{ item }">
              <div class="product-card">
                <Card  :key="item" :item="item" />
              </div>
            </template>

          </ais-hits>
        </div>
      </div>

    </div>
    <div v-show="specifications" class="filter-by detailed-info-modal-container flex-1 flex overflow-hidden border-l-2 border-b-2 border-black-800 h-full">
      <div class="product-specs-content flex-1 overflow-y-scroll">
        <div class="product-specs-header"  @click="openSpecs">
          <i class="font-bold">x</i>
          <h4>Filter Products</h4>
        </div>
        <div class="product-specs-body">


          <div class="product-listing-sidebar m-1">
            <div class="search-panel__filters">
              Brands
              <ais-menu attribute="brand"  :limit="5" :show-more='true'  />
            </div>
            <div class="search-panel__filters">
              Shape
              <ais-refinement-list attribute="shape" :limit="0" :show-more='true' />
            </div>
            <div class="search-panel__filters">
              Gender
              <ais-refinement-list attribute="gender" :transform-items="transformItemsB" :limit="0" :show-more='true' />
            </div>
            <div class="search-panel__filters">
              Lens type
              <ais-refinement-list attribute="lens_material" :limit="0" :show-more='true' />
            </div>
            <div class="search-panel__filters">
              Lens colour
              <ais-refinement-list attribute="lens_color" :limit="0" :show-more='true' />
            </div>
            <div class="search-panel__filters">
              Collections
              <ais-refinement-list attribute="tags" :limit="0" :show-more='true' />
            </div>
            <div class="search-panel__filters">
              Best Seller
              <ais-refinement-list attribute="best_sellers" :limit="0" :show-more='true' />
            </div>
            <div class="search-panel__filters">
              <ais-clear-refinements />
            </div>

          </div>



        </div>


      </div></div>






    <div class="pagination">
      <ais-pagination @click="moveUp" />
    </div>
  </ais-instant-search>

</template>

<script>
import "./Search.scss";
import Card from "../../components/molecules/Card/index";

import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite-min.css';

import { history as historyRouter } from 'instantsearch.js/es/lib/routers';

import { useMeta } from "vue-meta/dist/vue-meta.esm-browser";
import { ref, watchEffect, onBeforeMount } from 'vue'

import { useRoute } from 'vue-router'

//brand data
import data from "../../appData";

import { AisInstantSearch, AisSearchBox,AisBreadcrumb, AisHits, AisConfigure,AisPagination,AisRefinementList,AisMenu,AisSortBy,AisClearRefinements,AisCurrentRefinements } from 'vue-instantsearch/vue3/es'

const indexName = process.env.VUE_APP_DATABASE;


// Returns a slug from the category name.
// Spaces are replaced by "+" to make
// the URL easier to read and other
// characters are encoded.
function getBrandSlug(name) {
  return name.split(' ').map(encodeURIComponent).join('+');
}

// Returns a name from the category slug.
// The "+" are replaced by spaces and other
// characters are decoded.
function getBrandName(slug) {
  return slug.split('+').map(decodeURIComponent).join(' ');
}

const routing = {
  router: historyRouter({
    // windowTitle({ brand, query, page }) {


    //   const queryTitle = query ? `Results for "${query}"` : '9999 Sunglass Hut® South Africa Online Store | Products';

    //   if(page > 1) {
    //     return `${queryTitle} | ${brand} | Page ${page} `;
    //   }

    //   if (brand) {
    //     return `${queryTitle} | ${brand} `;
    //   }

    //   // return queryTitle;
    // },

    createURL({ qsModule, routeState, location }) {
      const urlParts = location.href.match(/^(.*?)\/products/);
      const baseUrl = `${urlParts ? urlParts[1] : ''}/`;



      const brandPath = routeState.brand
          ? `${getBrandSlug(routeState.brand)}/`
          : '';
      const queryParameters = {};

      if (routeState.query) {
        queryParameters.query = encodeURIComponent(routeState.query);
      }
      if (routeState.page !== 1) {
        queryParameters.page = routeState.page;
      }
      if (routeState.genders) {
        queryParameters.genders = routeState.genders.map(encodeURIComponent);
      }
      if (routeState.shapes) {
        queryParameters.shapes = routeState.shapes.map(encodeURIComponent);
      }
      if (routeState.shapes) {
        queryParameters.shapes = routeState.shapes.map(encodeURIComponent);
      }
      if (routeState.collections) {
        queryParameters.collections = routeState.collections.map(encodeURIComponent);
      }
      if (routeState.collection) {
        queryParameters.collection = routeState.collection.map(encodeURIComponent);
      }


      const queryString = qsModule.stringify(queryParameters, {
        addQueryPrefix: true,
        arrayFormat: 'repeat',
      });

      // KEEP UTM CODES
      var url_string = window.location.href
      var url = new URL(url_string);
      var utm_source = url.searchParams.get("utm_source");
      //&utm_source=clab&utm_medium=email&utm_content=brand1&utm_campaign=220408-SGHRSAEasterPromo
      if(utm_source !== null)
      {
        var utm_medium = url.searchParams.get("utm_medium");
        var utm_content = url.searchParams.get("utm_content");
        var utm_campaign = url.searchParams.get("utm_campaign");
        console.log(queryString,'queryString');
        if(queryString.length < 1)
        {
          return `${baseUrl}products/${brandPath}${queryString}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_content=${utm_content}&utm_campaign=${utm_campaign}`;
        }
        else
        {
          return `${baseUrl}products/${brandPath}${queryString}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_content=${utm_content}&utm_campaign=${utm_campaign}`;
        }


      }
      else
      {
        return `${baseUrl}products/${brandPath}${queryString}`;
      }




    },

    parseURL({ qsModule, location }) {
      const pathnameMatches = location.pathname.match(/products\/(.*?)\/?$/);
      const brand = getBrandName(
          (pathnameMatches && pathnameMatches[1]) || ''
      );

      const { query = '', page, genders = [] , shapes = [],collections = [],collection = [],} = qsModule.parse(
          location.search.slice(1)
      );
      // `qs` does not return an array when there's a single value.
      const allGenders = Array.isArray(genders)
          ? genders
          : [genders].filter(Boolean);

      const allShapes = Array.isArray(shapes)
          ? shapes
          : [shapes].filter(Boolean);

      const allCollections = Array.isArray(collections)
          ? collections
          : [collections].filter(Boolean);

      const allCollection = Array.isArray(collection)
          ? collection
          : [collection].filter(Boolean);

      return {
        query: decodeURIComponent(query),
        page,
        genders: allGenders.map(decodeURIComponent),
        shapes: allShapes.map(decodeURIComponent),
        collections:allCollections.map(decodeURIComponent),
        collection:allCollection.map(decodeURIComponent),
        brand,
      };
    },
  }),

  stateMapping: {
    stateToRoute(uiState) {
      const indexUiState = uiState[indexName] || {};

      return {
        query: indexUiState.query,
        page: indexUiState.page,
        genders:
            indexUiState.refinementList && indexUiState.refinementList.gender,
        shapes:
            indexUiState.refinementList && indexUiState.refinementList.shape,
        collections:
            indexUiState.refinementList && indexUiState.refinementList.tags,
        collection:
            indexUiState.refinementList && indexUiState.refinementList.best_sellers,
        brand: indexUiState.menu && indexUiState.menu.brand,

      };
    },

    routeToState(routeState) {
      return {
        [indexName]: {
          query: routeState.query,
          page: routeState.page,
          menu: {
            brand: routeState.brand,
          },
          refinementList: {
            gender: routeState.genders,
            shape:routeState.shapes,
            tags:routeState.collections,
            best_sellers:routeState.collection,
          },
        },
      };
    },
  },
};


export default {

  name: "Search",
  components: { Card,AisSearchBox,AisInstantSearch,AisBreadcrumb,AisHits,AisConfigure,AisPagination,AisRefinementList,AisMenu ,AisSortBy,AisClearRefinements,AisCurrentRefinements },
  data() {
    return {

      searchClient: algoliasearch(
          'ZIETC15FK5',
          'f368650b8a7822fad1bda1f8be2ada53'
      ),
      routing,
      brandx:false,
      specifications:false,
      titleB:false,
      brands: data.brandInfo.list,
      pageHeading: '',
      pageDescription: '',
      metaTitle: '',
      metaDesc: '',
    };
  },
  watch: {
    '$route'() {
      this.updatepageHeading();
    },
  },
  methods: {
    moveUp(){
      window.scrollTo(100,0);
    },
    openSpecs() {
      this.specifications = !this.specifications;
    },

    transformItemsB(items) {

      return items.map(item => ({        
        ...item,
        label: item.label.toUpperCase(),
      }));
    },

    transformItems(items) {
      //get brand for info block
      console.log(items,'items');
      if(items.length)
      {
        this.brandx = items[0].label;
      }
      else
      {
        this.brandx = "";
      }

      return items.map(item => ({
        ...item,
        label: item.label,
      }));
    },

    updatepageHeading() {
      const currentPath = this.$route.fullPath;

      const brandNameMatch = currentPath.match(/\/products\/([^(.*?),]+)/);
      const brandName = brandNameMatch ? brandNameMatch[1].replace(/\/$/, '') : null;

      // Set title based on URL
      if (currentPath.includes('/products/?genders=Male')) {
        this.pageHeading = `<h1> Explore All Men’s Products: Sunglass Hut Catalogue</h1>`;
        this.pageDescription = `<p> Elevate your look with our men&apos;s sunglasses collection. From contemporary to classic, each pair adds a touch of sophistication. Explore now for refined style.</p>`;
        
      } else if (currentPath.includes('/products/?genders=Female')) {
        this.pageHeading = `<h1>Explore All Woman’s Products: Sunglass Hut Catalogue</h1>`;
        this.pageDescription = `<p> Discover elevated style with our women&apos;s sunglasses collection. From trendy designs to timeless classics, find the perfect pair to accentuate your unique elegance. Shop now for sunglasses that make a statement in every look.<p>`;
        
      } else if (brandName) {
        // this.pageHeading = `<h1>${brandName}</h1>`;
        this.pageHeading = ``;
        this.pageDescription = ``;
        
        // document.title = `asdadSunglass Hut® South Africa Online Store | Products | ${brandName}`;
      } else if (currentPath.includes('/products/')) {
        this.pageHeading = `<h1>Explore All Products: Sunglass Hut Catalogue</h1>`;
        this.pageDescription = `<p> Discover a world of eyewear excellence with our diverse catalog. From trending styles to timeless classics, find the perfect sunglasses to elevate your style. Embrace quality and individuality in every pair. Explore now! </p>`;
        // document.title = `1313Sunglass Hut® South Africa Online Store | Products`;
      }else {
        // Default title if no match
        this.pageHeading = '';
        this.pageDescription = '';
        
      }
    },
  },

  created () {
    this.updatepageHeading();

  },
  mounted() {

  },
  setup(){
  //   const routePath = useRoute();

  //   console.log("route path",routePath.fullPath);
  //   const currentBrandNameMatch = routePath.fullPath.match(/\/products\/([^(.*?),]+)/);
  //   const currentBrandName = currentBrandNameMatch ? currentBrandNameMatch[1].replace(/\/$/, '') : null;

  //   console.log("console",currentBrandName);

  //   let metaTitle = ref(null);
  //   let metaDesc = ref(null);

  //   if(routePath.fullPath === '/products/') {
  //     metaTitle.value = "Explore All Products: Sunglass Hut Catalogue";
  //     metaDesc.value = "Explore our diverse catalog of premium eyewear, featuring the latest styles and timeless classics. Find the perfect blend of fashion and function with our curated collection of sunglasses. Shop now for a wide selection of Ray-Ban®, Oakley, Versace, Prada, Tom Ford and more. Elevate your look with top-quality, polarised eyewear, designed to complement your unique style. Unleash your individuality – discover the art of eyewear at its finest.";
  //   }

  //   if(routePath.fullPath === '/products/?genders=Male') {
  //     metaTitle.value = "Explore All Men’s Products: Sunglass Hut Catalogue";
  //     metaDesc.value = "Elevate your look with our curated collection of men's sunglasses. From contemporary styles to timeless classics, discover sunglasses that blend sophistication with trendsetting designs. Shop now for the the perfect pair to complement your style and shield your eyes in unparalleled fashion.";
  //   }

  //   if(routePath.fullPath === '/products/?genders=Female') {
  //     metaTitle.value = "Explore All Woman’s Products: Sunglass Hut Catalogue";
  //     metaDesc.value = "Explore stylish sunglasses for women that elevate your look. Dive into our curated collection, showcasing trendy designs and timeless classics. Find the perfect pair to express your unique elegance. Shop now for sunglasses that radiate with style.";
  //   }

  //   if(currentBrandName) {
  //     var url_string = window.location.href
  //     var url = new URL(url_string);
  //     var urlBrandPage = url.searchParams.get("page");
  //     console.log("page india",urlBrandPage);

  //     metaTitle.value = "Sunglass Hut® South Africa Online Store | Products | "+ currentBrandName;
  //     metaDesc.value = "Elevate your style with "+ currentBrandName +" sunglasses, combining fashion-forward designs with unparalleled quality. Shop now in South Africa for premium eyewear, blending style and UV protection seamlessly.";
  //   }

  //   useMeta({
  //     title: metaTitle.value ? metaTitle.value : "",
  //     description: metaDesc.value ? metaDesc.value : "",
  //   });

    const route = useRoute();
    let metaTitle = ref(null);
    let metaDesc = ref(null);

    const updateMetadata = () => {
      const currentBrandNameMatch = route.fullPath.match(/\/products\/([^(.*?),]+)/);
      const currentBrandName = currentBrandNameMatch ? currentBrandNameMatch[1].replace(/\/$/, '') : null;

      if (route.fullPath === '/products/') {
        metaTitle.value = "Explore All Products: Sunglass Hut Catalogue";
        metaDesc.value = "Explore our diverse catalog of premium eyewear, featuring the latest styles and timeless classics. Find the perfect blend of fashion and function with our curated collection of sunglasses. Shop now for a wide selection of Ray-Ban®, Oakley, Versace, Prada, Tom Ford and more. Elevate your look with top-quality, polarised eyewear, designed to complement your unique style. Unleash your individuality – discover the art of eyewear at its finest.";
      } else if (route.fullPath.includes('/products/?genders=Male')) {
        metaTitle.value = "Explore All Men’s Products: Sunglass Hut Catalogue";
        metaDesc.value = "Elevate your look with our curated collection of men's sunglasses. From contemporary styles to timeless classics, discover sunglasses that blend sophistication with trendsetting designs. Shop now for the perfect pair to complement your style and shield your eyes in unparalleled fashion.";
      } else if (route.fullPath.includes('/products/?genders=Female')) {
        metaTitle.value = "Explore All Woman’s Products: Sunglass Hut Catalogue";
        metaDesc.value = "Explore stylish sunglasses for women that elevate your look. Dive into our curated collection, showcasing trendy designs and timeless classics. Find the perfect pair to express your unique elegance. Shop now for sunglasses that radiate with style.";
      } else if (currentBrandName) {
        const page = route.query.page;

        if (page && page !== '1') {
          metaTitle.value = `Sunglass Hut® South Africa Online Store | Products | ${currentBrandName} | Page ${page}`;
          metaDesc.value = `Elevate your style with ${currentBrandName} sunglasses, combining fashion-forward designs with unparalleled quality. Shop now in South Africa for premium eyewear, blending style and UV protection seamlessly.`;
        } else {
          metaTitle.value = `Sunglass Hut® South Africa Online Store | Products | ${currentBrandName}`;
          metaDesc.value = `Elevate your style with ${currentBrandName} sunglasses, combining fashion-forward designs with unparalleled quality. Shop now in South Africa for premium eyewear, blending style and UV protection seamlessly.`;
        }
      }
    };

    watchEffect(() => {
      updateMetadata();
      console.log("meta value title", metaTitle.value);
    });

    onBeforeMount(() => {
      updateMetadata();
    });

    useMeta({
      title: metaTitle.value ? metaTitle.value : "",
      description: metaDesc.value ? metaDesc.value : "",
    });
  },
};
</script>