export default {
  content: [
    {
      header: "privacy policy",
      text:
          "<p>Vaid From April 2019</p>" +
        "<br>" +
        "<p>“Sunglass Hut”, wholly owned by Luxottica group SPA, is a global retail chain for sale of Luxottica branded eyewear and accessories (“Luxottica products”). </p>" +
        "<br>" +
        "<p>Sunglass Hut is a premier player in the South Africa retail sector which came into existence in 2007.</p>"+ "<br>"

    },
    {
      header: "important terms",
      text:

        "<p>Please read the terms of this Privacy Policy carefully. This Privacy policy, along with the “Terms of Use” constitutes an “electronic record” in the form of an “electronic contract” as defined under the Information Technology Act, 2000 ( “ITA”) between “Sunglass Hut” and the user of this Website. This Privacy Policy does not require any physical, electronic or digital signature.</p>" +
        "<br>" +
        "<p>This Privacy Policy forms an integral part of the “Terms of Use” of the Website.  If you do not agree with the terms of this Privacy Policy please do not use this Website.  By visiting this Website you (a) unconditionally accept, and agree to be bound by the “Terms of Use” of the Website, read with this Privacy Policy; (b) expressly and voluntarily consent to the processing of your personal information as defined in the Protection of Personal Information Act, No.4 of 2013 “ POPIA”, which includes the collection, receipt, possession, storage, usage, dealing, handling or transfer of your personal information by Sunglass Hut in accordance with the terms of this Privacy Policy." +
        "<br/>" +
        "<p>The Privacy Policy can undergo a change at any time at the discretion of ROD. Therefore, it is suggested that you regularly check the Website to be apprised of the latest changes made to the Privacy Policy.</p><br/>",
    },
    {
      header: "privacy policy - introduction",
      text:

        "<p>Sunglass Hut is committed to ensuring privacy of information of users of its website(s). In furtherance of the same, Sunglass Hut has framed this Privacy Policy in accordance with the Information Technology Act, 2000(“ITA”) and Protection of Personal Information Act, 2013 (“POPIA”) read with the applicable Rules thereunder. </p>" +
        "<p>This Privacy Policy is designed to familiarize users of this Website with (a) type of information that users may share with ROD, or that ROD collect from users; (b) practices and policies of ROD relating to collection, storage, dealing, transfer, disclosure etc. of information pertaining to users; (c) purpose of collection and usage of such information, and related matters.</p><br/>",
    },
    {
      header: "types of user information",
      text:

        "<p>1.Collection of “Information”: Sunglass Hut may request for, collect or gather such “Information” (which shall include data) as it deems necessary, relevant or incidental for rendering an improved shopping experience to users and for other purposes as specified in Paragraph 6 below.</p>" +
        "<br>" +
        "<p>2.Personal Information: Such “Information” may include Personal Information relating to users such as: (a) user’s name, address, telephone number, e-mail address, postal address, delivery address (if different), gender, purchase history etc. (b) financial information such as bank account or creditor debit-card information; (c) physical condition of user; (d) a description of the item requested or purchased;(e) the internet protocol (“IP”) address of your computer; (f) Any other “sensitive personal data or information” relating to the user as defined in both above mentioned Acts.</p>" +
        "<br>" +
        "<p>3.Non-personal information: Sunglass Hut may also collect certain non-personal Information from the user, when the user visits and / or uses the Website.  Such non-personal Information would include but not be limited to information such as geographical location of user, IP address, type of browser, operating system of user’s device, details of usage of Website etc.  Such non-personal Information is collected through various ways including “cookies” as more particularly discussed hereinafter. Such information is not used to track information about individuals, but is used on an aggregate level to customize our site, improve the on-line experience and plan advertising and promotions.</p><br/>",
    },
    {
      header: "cookies",
      text:

        "<p>This Website uses Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics use “cookies”, which are text files placed on your computer, to help the Website analyze how users use the site. The information generated by the cookie about your use of the Website will be transmitted to and stored by Google servers.</p>" +
        "<br>" +
        "<p>The IP-anonymisation is active on this Website. Please note that on this Website, Google Analytics code is supplemented by “-anonymizelp();” to ensure an anonymized collection of IP addresses (so called IP-masking).</p>" +
        "<br>" +
        "<p>Google will use this information on behalf of the operator of this Website for the purpose of evaluating your use of the Website, compiling reports on Website activity for Website operators and providing them other services relating to Website activity and internet usage.</p>" +
        "<br>" +
        "<p>The IP-address, that your Browser conveys within the scope of Google Analytics will not be associated with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this Website. You can also opt-out from being tracked by Google Analytics with effect for the future by downloading and installing </p>" +
        "<br>" +
        "<p>Google Analytics Opt out Browser Addon for your current web browser: http://tools.google.com/dlpage/gaoptout?hl=en.</p>"+
          "<br>"+
        "<p>4.Internet database: Sunglass Hut has created an Internet database to allow consumers to store their personal information and retain email preferences. Registration is entirely voluntary and you have the ability to correct or delete the information at any time. This information can be accessed through the “My Account” section of the site. </p><br/>",
    },

    {
      header: "modes of collection of user information",
      text:

        "<p>The Information referred to above may be collected or received by Sunglass Hut (a) directly from the user, when the user either provides any specific information on the Website; or (b) from use of the Website by the user; or (c) from third parties who have collected any Information relating to the user, and who have shared it with Sunglass Hut.</p>" +
        "<br>" +
        "<p>There are times when you may provide information to us voluntarily, otherwise than through use of the Website. This occurs when you provide your feedback to us though e-mails, return forms, letters or telephone calls. We may use this information to respond to your query and to keep track of customer feedback.</p><br/>",
    },
    {
      header: "purpose of collection and usage of information",
      text:

        "<p>Sunglass Hut may collect, store and use Information for any purpose as may be permissible under Applicable Laws, including but limited to the following:</p>" +
        "<p>(a) Facilitation of your use of the Website, handling and execution of customer inquiries, communication with user about Luxottica products etc.;</p>" +
        "<p>(b)  Provide you with details of important schemes, offers, product details, new product launches, changes to terms of use of the Website and make other important announcements etc;</p>" +
        "<p>(c)  Maintain accounts of the user, display contents inputted by user such as user profile, user wishlist etc.;</p>" +
        "<p>(d)  Help recommend any product to the user and services that may be of interest to him / her;</p>" +
        "<p>(e)   Personalize your experience on the Website by presenting advertisements, products and offers tailored to your preferences;</p>" +
        "<p>(f)  Customization, administration etc. of the Website, location of errors, Website testing, data analysis for the Website etc;</p>" +
        "<p>(g)  Provision of various services on the Website;</p>" +
        "<p>(h)  To protect integrity of the Website, improve our platform, prevent or detect fraud or abuse of our Website;</p>" +
        "<p>(i)  To conduct analytical studies on various aspects including user behavior, user preferences etc.</p>" +
        "<p>(j)  Enable third parties to carry out technical, logistical or other functions on our behalf;</p>" +
        "<p>(k) To permit employees of ROD to contact users, and enable them to inform about the products newly launched, resolve user queries, issues, grievances etc;</p>" +
        "<p>(l) To trace computer resources of any person for the purposes of determining compliance with the provisions of the Information Technology Act, 2000 and / or any other law for the time being in force.</p><br/>",
    },
    {
      header: "disclosure and retention of user information",
      text:

        "<p>Sunglass Hut consider Information about its customers as an important" +
        " part of its business.  Accordingly, Sunglass Hut shall not engage in sale" +
        " of Information relating to users to third parties.  However, Sunglass Hut may share user information third parties in the circumstances specified herein below, after reasonably assuring itself that such third parties have undertaken to maintain confidentiality of Personal Information relating to the users: </p>" +
        "<br>" +
        "<p>(a)  Third Party Service Providers: Sunglass Hut may engage third party" +
        " service providers to render various services and perform various" +
        " function in relation to the business undertaken on the Website, and / or for the Purpose(s) discussed above.  For instance, ROD may engage third party services providers for maintenance of its website, analyzing data, providing marketing assistance, provision of customer services etc.  Such third party service providers/retailers would have access to Personal Information of users for the purpose of performing their functions / rendering their services etc. </p>" +
        "<br>" +
        "<p>(b)  Business Transfers: Sunglass Hut may transfer or otherwise share" +
        " some or all of its assets, including your Information in" +
        " connection with a merger, acquisition, reorganization or sale of assets or business for any reason whatsoever. Should such a sale or transfer occur, Sunglass Hut will reasonably ensure that the Information you have provided and which we have collected is stored and used by the transferee in a manner that is consistent with this Privacy Policy. Any third party to which Sunglass Hut transfers or sells as aforesaid will have the right to continue to use the information that you provide to us or collected by us immediately prior to the transfer.</p>" +
        "<br>" +
        "<p> (c)  Government Agency: Sunglass Hut may share any Information relating" +
        " to users (i) with Government agencies mandated under the law to" +
        " obtain Information relating to users from Sunglass Hut; (ii) any third party, when Sunglass Hut is required to disclose the same under an order of a Government or judicial authority under any law for the time being in force, or where such disclosure is necessary for the compliance of a legal obligation.</p>" +
        "<br>" +
        "<p>(d)  Sunglass Hut may share the Information relating to customers with" +
        " Luxottica and its group companies, for or connection with any of" +
        " the Purposes specified in Paragraph above.</p>" +
        "<br>" +
        "<p> (e)  With User Consent: Without prejudice to the aforesaid," +
        " Sunglass Hut may disclose Personal Information relating to the user with his / her consent.  For this purpose, Sunglass Hut may send a prior notice to the user before sharing Personal Information relating to the user with third parties.  In case no objection or intimation is received from the User, Sunglass Hut would presume that User has granted its consent for sharing of said Information with third parties.\n" +
        "\n" +
        "By using or visiting the Website and agreeing to the terms of this" +
        " Privacy Policy, User shall be construed to have consented to, and" +
        " accepted the disclosure of his Information to third parties as provided under this Privacy Policy.</p>" +
        "<br>" +
        "<p>Sunglass Hut will share your Personal Information internally with those" +
        " staff members who need it to complete your queries or carry out" +
        " your instructions regarding the receipt of marketing information.</p>" +
        "<br>" +
        "<p>Sunglass Hut shall keep sensitive personal data or information for only as long as the purposes for which the information may lawfully be used or is otherwise required under any other law for the time being in force. For those customers who have registered for the mailing list, their personal information is kept until we are notified that they no longer want their information stored.</p><br/>",
    },
    {
      header: "security practices and procedures for protection of information",
      text:

        "<p>In order to make every effort to ensure that your experience at" +
        " in.sunglasshut.com is secure, we use encryption technology to" +
        " protect you against the loss, misuse or alteration of your personal information. When you fill out any contact forms or access your account, a secure server encrypts all of your information through the use of Secure Socket Layers (SSLs).</p>" +
        "<p>Sunglass Hut follows the International Standard IS/ISO/IEC 27001 on" +
        ' "Information Technology - Security Techniques - Information' +
        " Security Management System – Requirements” for ensuring protection" +
        " of user Information in its possession.</p>" +
        "<p>To be sure you’re browsing secure pages for transactions, check your Web browser’s status bar (located at the bottom of the window) for the closed padlock icon. This icon appears in your web browser to tell you that you are viewing a secure web page. Also, all browsers display an “s” after the “http” (https://) in the Web site address to indicate that you are in a secure environment</p><br/>",
    },
    {
      header: "accuracy and protection of personal information",
      text:

        "<p>Sunglass Hut relies on our customers to notify us of any changes in" +
        " personal information. Should inaccurate information come to our" +
        " attention, we will investigate and correct the information and, if" +
        " necessary, apprise you of the change. Only those staff members who need your personal information in order to respond to your requests are given access to it. Employees are provided with training and information regarding the proper handling of personal information. All information stored in our computer system is protected from unauthorized access and information that is stored in document form is kept in secure locations to prevent access by unauthorized persons.</p><br/>",
    },
    {
      header: "privacy officer",
      text:

        "<p>In order to ensure compliance with these policies, Sunglass Hut has" +
        " appointed a Privacy Officer to oversee all aspects of its privacy" +
        " policies and practices. If you are dissatisfied with Sunglass Hut’s privacy policies or practices, you should send a written request or complaint to the Privacy Officer</p>" +
        "<p>The Privacy Officer will investigate the matter and respond to your request within 30 days. In the event that you become aware that the information we have about you is incorrect, you should notify the Privacy Officer, who will ensure the information is updated. In case of a complaint, the Privacy Officer will take corrective action (if necessary) and will advise you of the steps taken to correct the problem. If you are still unsatisfied with the actions taken, you may be entitled to make a written complaint to the appropriate government or legislative body.</p><br/>",
    },
    {
      header: "copyright",
      text:

        "<p>We have Copyright over this Privacy Policy. Use of this Privacy" +
        " Policy or extracts thereof for commercial purposes will be subject to legal action.</p><br/>",
    },
    {
      header: "safety and security tips",
      text: "<p>Protect your passwords.</p>",
    },
  ],
  documentTitle: [
    {
      header: "PAIA Manual and Forms",
      text: "",
    },
  ],
  document: [
    {
      header: "PAIA Manual",
      text: "Luxottica Southern Africa (Proprietary) Limited",
      link: "https://za.sunglasshut.com/shared_assets/PAIA_MANUAL_2023.pdf"
    },
    {
      header: "PAIA Form 02",
      text: "Request for Access",
      link: "https://za.sunglasshut.com/shared_assets/PAIA-Form02-Request_for_Access_to_Record-Reg7.pdf"
    },
    {
      header: "PAIA Form 03",
      text: "Request and of Fees Payable",
      link: "https://za.sunglasshut.com/shared_assets/PAIA-Form03-Outcome_of_Request_and_of_Fees_Payable-Reg8.pdf"
    }
  ]
};
