export default {
  list: [
    {
      id: "1",
      brand: "ray-ban",
      name: "ray-ban",
      imageOne:
        "https://cdn.zando.co.za/cms/sunglass-hut/8056597581868_030A.jpg",
      imageTwo:
        "https://cdn.zando.co.za/cms/sunglass-hut/8056597581868_000A.jpg",
      slug: "/sunglasshut-sa-women/sunglass-sa-women-cat-eye/green-vintage-sunglasses-8056597548937",
      productId: "UPC# 8056597548939",
      frameColor: "white",
      // frameMaterial: string,
      lensColor: "green vintage",
      lensMaterial: "Acetate",
      gender: "female",
      shape: "Cat Eye",
      shapeUrl: "cat-eye",
      price: "R 3 200",
    },
    {
      id: "2",
      brand: "ray-ban",
      name: "ray-ban",
      imageOne:
        "https://cdn.zando.co.za/cms/sunglass-hut/8056597581868_030A.jpg",
      imageTwo:
        "https://cdn.zando.co.za/cms/sunglass-hut/8056597581868_000A.jpg",
      slug: "/sunglasshut-sa-women/sunglass-sa-women-cat-eye/green-vintage-sunglasses-8056597548938",
      productId: "UPC# 8056597548939",
      frameColor: "white",
      // frameMaterial: string,
      lensColor: "green vintage",
      lensMaterial: "Acetate",
      gender: "female",
      shape: "Cat Eye",
      shapeUrl: "cat-eye",
      price: "R 3 200",
    },
    {
      id: "3",
      brand: "ray-ban",
      name: "ray-ban",
      imageOne:
        "https://cdn.zando.co.za/cms/sunglass-hut/8056597581868_030A.jpg",
      imageTwo:
        "https://cdn.zando.co.za/cms/sunglass-hut/8056597581868_000A.jpg",
      slug: "/sunglasshut-sa-women/sunglass-sa-women-cat-eye/green-vintage-sunglasses-8056597548939",
      productId: "UPC# 8056597548939",
      frameColor: "white",
      // frameMaterial: string,
      lensColor: "green vintage",
      lensMaterial: "Acetate",
      gender: "female",
      shape: "Cat Eye",
      shapeUrl: "cat-eye",
      price: "R 3 200",
    },
    {
      id: "1",
      brand: "ray-ban",
      name: "ray-ban",
      imageOne:
        "https://cdn.zando.co.za/cms/sunglass-hut/8056597581868_030A.jpg",
      imageTwo:
        "https://cdn.zando.co.za/cms/sunglass-hut/8056597581868_000A.jpg",
      slug: "/sunglasshut-sa-women/sunglass-sa-women-cat-eye/green-vintage-sunglasses-8056597548937",
      productId: "UPC# 8056597548939",
      frameColor: "white",
      // frameMaterial: string,
      lensColor: "green vintage",
      lensMaterial: "Acetate",
      gender: "female",
      shape: "Cat Eye",
      shapeUrl: "cat-eye",
      price: "R 3 200",
    },
    {
      id: "2",
      brand: "ray-ban",
      name: "ray-ban",
      imageOne:
        "https://cdn.zando.co.za/cms/sunglass-hut/8056597581868_030A.jpg",
      imageTwo:
        "https://cdn.zando.co.za/cms/sunglass-hut/8056597581868_000A.jpg",
      slug: "/sunglasshut-sa-women/sunglass-sa-women-cat-eye/green-vintage-sunglasses-8056597548938",
      productId: "UPC# 8056597548939",
      frameColor: "white",
      // frameMaterial: string,
      lensColor: "green vintage",
      lensMaterial: "Acetate",
      gender: "female",
      shape: "Cat Eye",
      shapeUrl: "cat-eye",
      price: "R 3 200",
    },
    {
      id: "3",
      brand: "ray-ban",
      name: "ray-ban",
      imageOne:
        "https://cdn.zando.co.za/cms/sunglass-hut/8056597581868_030A.jpg",
      imageTwo:
        "https://cdn.zando.co.za/cms/sunglass-hut/8056597581868_000A.jpg",
      slug: "/sunglasshut-sa-women/sunglass-sa-women-cat-eye/green-vintage-sunglasses-8056597548939",
      productId: "UPC# 8056597548939",
      frameColor: "white",
      // frameMaterial: string,
      lensColor: "green vintage",
      lensMaterial: "Acetate",
      gender: "female",
      shape: "Cat Eye",
      shapeUrl: "cat-eye",
      price: "R 3 200",
    },
  ],
};
