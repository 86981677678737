export default {
  image: {
    alt: "Logo White",
    src: {
      mobile:
        "https://images.pexels.com/photos/10204089/pexels-photo-10204089.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      desktop:
        "https://images.pexels.com/photos/10204089/pexels-photo-10204089.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=1440",
      tablet:
        "https://images.pexels.com/photos/10204089/pexels-photo-10204089.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=1200",
    },
  },
};
