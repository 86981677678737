<template>
  <li v-if="windowWidth" @click="toggleMenuItem" class="menu">
    <div class="menu-wrap">
      <slot name="list-item" />
      <span v-if="child">
      <i class="fa fa-" v-bind:class="['fa-plus', { 'fa-minus' : menuItemOpen }]" aria-hidden="true"></i>
        <!-- {{ menuItemOpen ? "-" : "+" }} -->
      </span>
    </div>
    <div v-if="menuItemOpen && child" class="menu-list">
      <slot name="children-items" />
    </div>
  </li>
  <li
    v-else
    @mouseenter="toggleMenuItem"
    @mouseleave="toggleMenuItem"
    class="menu"
  >
    <div class="menu-wrap">
      <slot name="list-item" />
    </div>
    <div v-if="menuItemOpen && child" class="menu-list">
      <slot name="children-items" />
    </div>
  </li>
</template>

<script>
export default {
  name: "MenuItems",
  props: ["child"],
  data() {
    return {
      menuItemOpen: false,
      windowWidth: window.innerWidth,
    };
  },
  created() {
    window.addEventListener("load", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("load", this.onResize);
  },
  methods: {
    toggleMenuItem() {
      this.menuItemOpen = !this.menuItemOpen;
      //console.log(this.menuItemOpen);
    },
    onResize() {
      this.windowWidth = window.matchMedia("(max-width: 1080px)").matches;
      //console.log(this.windowWidth, "window width");
    },
  },
};
</script>
