<template>
  <div class="text-component">
    <h2>{{ heading }}</h2>
    <p v-if="typeof text === 'string'" v-html="text" />
    <p v-else v-for="t in text" :key="t" v-html="t" />
    <a :href="link"><Button :text="button" v-if="button" /></a>
  </div>
</template>

<script>
import "./TextComponent.scss";
import Button from "../../atoms/Button";
export default {
  name: "TextComponent",
  components: { Button },
  props: ["heading", "text", "button","link"],
};
</script>
