<template>
  <div class="country-filter">
    <div class="country-filter-wrap" @click="openFilter">
      <div class="country-filter-click">SA / EN</div>
    </div>
    <Modal
      class="country-filter-modal"
      :class="{ showModal: open }"
      @click="openFilter"
    />
    <div class="country-filter-list" v-if="open">
      <div class="country-filter-wrap">
        <h2>Country: South Africa</h2>
        <div @click="openFilter">
          <i class="fa fa-times" />
        </div>
      </div>
      <List>
        <template v-slot:items>
          <CountryList :list="list" />
        </template>
      </List>
    </div>
  </div>
</template>

<script>
import "./CountryFilter.scss";
import Modal from "../../atoms/Modal";
import List from "../../atoms/List";
import CountryList from "../../molecules/CountryList";
import data from "../../../appData/";
export default {
  name: "CountryFilter",
  components: { Modal, List, CountryList },
 // inject: ["data"],
  data() {
    return {
      open: false,
      list: data.countries.list,
    };
  },
  methods: {
    openFilter() {
      this.open = !this.open;
    },
  },
};
</script>
